.root {
  height: 52px;
  display: flex;
}

.previewContainer {
  width: 40px;
  flex-grow: 0;
}

.bodyContainer {
  flex-grow: 1;
  min-width: 0;
}

.statusContainer {
  width: 40px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  height: 100%;
}
