.root {
    display: flex;
    align-items: center;
}

.status {
    margin-left: auto;
    flex-grow: 0;
    width: 24px;
    height: 24px;
}
