.root {
  padding: 32px 20px 20px;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list > li + li {
  margin-top: 24px;
}
