.root {
  display: block;
  height: 100%;
}

.imgContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: stretch;
  background-color: #ffffff;
  border-radius: 6px;
  overflow: hidden;
}

.imgContainer > img {
  max-width: 100%;
  max-height: 100%;
}
