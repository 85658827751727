.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  line-height: 15px;
  padding-left: 16px;
;
}

.root.failed .status {
  color: #FF723A;
}

.name {
  margin: 0;
}

.name > span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
}

.bar {
  display: flex;
  align-items: center;
}

.status {
  color: #9599A1;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
}

.actions {
  flex-grow: 0;
  margin-left: auto;
}

.actions > button {
  border: 0;
  outline: 0;
  padding: 0;
  color: #9599A1;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  cursor: pointer;
  background-color: transparent;
}

.actions > button:hover,
.actions > button:active {
  color: #25292B;
}

.actions > button + button {
  margin-left: 24px;
}

.progress {
  line-height: 6px;
}

.progress > :global(.ant-progress) {
  line-height: 6px;
  vertical-align: bottom;
}
