.root {
  width: 56px;
  height: 56px;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 17px;
}

.root > svg {
  vertical-align: top;
  max-width: 100%;
  height: 100%;
}
