// Antd styles customization

@import "~antd/lib/style/themes/index";
@import "~antd/lib/style/mixins/index";
@import "~antd/lib/button/style/mixin";
@import "~antd/lib/form/style/index";
@import "~antd/lib/select/style/index";
@import "~antd/lib/date-picker/style/index";

// Button
@btn-prefix-cls: ~'@{ant-prefix}-btn';
@btn-active-color: #008BD6;

.@{btn-prefix-cls} {
  &:hover,
  &:focus,
  &:active {
    .button-color(@btn-default-color, @btn-default-bg, @btn-active-color);
  }

  &-primary {
    &:hover,
    &:focus,
    &:active {
      .button-color(@btn-primary-color, @btn-active-color, @btn-active-color);
    }
  }
}

// Input
@input-active-border-color: #008BD6;
@input-placeholder-font-weight: 600;
@input-font-weight: 700;
@input-font-size: 13px;
@input-line-height: 22px;

.@{ant-prefix}-input {
  font-weight: @input-font-weight;
  font-size: @input-font-size;
  line-height: @input-line-height;

  &::placeholder {
    font-width: @input-placeholder-font-weight;
  }

  &:focus,
  &:active {
    border-color: @input-active-border-color;
  }

  &-affix-wrapper {
    &:focus,
    &-focused {
      box-shadow: none;
    }

    &-disabled:hover:not(.\@) {
      border-color: @input-border-color;
    }
  }

  &:focus,
  &-focused {
    box-shadow: none;
  }
}

// Picker
@picker-prefix-cls: ~'@{ant-prefix}-picker';

.@{picker-prefix-cls} {
  padding: @input-padding-vertical-base @input-padding-horizontal-base;
}

// Form
.@{form-item-prefix-cls} {
  &-has-feedback {
    > .@{ant-prefix}-select .@{ant-prefix}-select-arrow,
    > .@{ant-prefix}-select .@{ant-prefix}-select-clear,
    :not(.@{ant-prefix}-input-group-addon) > .@{ant-prefix}-select .@{ant-prefix}-select-arrow,
    :not(.@{ant-prefix}-input-group-addon) > .@{ant-prefix}-select .@{ant-prefix}-select-clear {
      right: 56px;
    }
  }
}

.ant-picker-focused {
  box-shadow: none;
}

.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  box-shadow: none;
}

.ant-form-item-has-warning .ant-input:focus,
.ant-form-item-has-warning .ant-input-affix-wrapper:focus,
.ant-form-item-has-warning .ant-input-focused,
.ant-form-item-has-warning .ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.ant-form-item-has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  box-shadow: none;
}

.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  box-shadow: none;
}

.ant-form-item-has-warning .ant-input-number-focused,
.ant-form-item-has-warning .ant-picker-focused,
.ant-form-item-has-warning .ant-input-number:focus,
.ant-form-item-has-warning .ant-picker:focus {
  box-shadow: none;
}

.ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
  box-shadow: none;
}

.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  box-shadow: none;
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  box-shadow: none;
}

.ant-form-item-has-error .ant-input-number-focused,
.ant-form-item-has-error .ant-picker-focused,
.ant-form-item-has-error .ant-input-number:focus,
.ant-form-item-has-error .ant-picker:focus {
  box-shadow: none;
}

.ant-form-item-has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  box-shadow: none;
}

.ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
  box-shadow: none;
}

.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
  box-shadow: none;
}

// Select
.@{select-prefix-cls} {
  &:not(&-customize-input) &-selector {
    .@{select-prefix-cls}-selection-search-input {
      font-weight: 700;
    }
  }

  &-multiple {
    .@{select-prefix-cls}-selection-item {
      background: @primary-color;
      color: #ffffff;
      border-radius: 54px;
      padding-left: 23px;
      padding-right: 23px;
    }

    .@{select-prefix-cls}-selection-search {
      margin-bottom: 0;
      margin-top: 0;

      &-input,
      &-mirror {
        height: 22px;
        line-height: 22px;
      }
    }

    .@{select-prefix-cls}-selector {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 56px;

      &::after {
        line-height: 0;
        margin: 0;
        display: none;
        //height: @select-height-without-border;
      }
    }
  }

  &-dropdown {
    box-shadow: none;
  }

  &-item {
    &-option {
      &-content {
        font-weight: 700;
      }

      &-state {
        display: none;
      }

      &-active:not(&-disabled) {
        color: @primary-color;
      }
    }
  }
}

.@{select-prefix-cls}-single {
  .@{select-prefix-cls}-selector {
    .@{select-prefix-cls}-selection-item {
      font-weight: 700;
    }
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
}

// Picker
.@{picker-prefix-cls} {
  display: flex;

  &-input {
    > input {
      font-weight: 700;
    }
  }
}

// Checkbox
@checkbox-prefix-cls: ~'@{ant-prefix}-checkbox';

.@{checkbox-prefix-cls} {
  & + span {
    font-weight: 700;
    font-size: 13px;
    padding-left: 12px;
    padding-right: 12px;
  }

  &-inner {
    &:after {
      left: 30%;
      width: 7px;
      height: 10px;
    }
  }
}

// Card
@card-prefix-cls: ~'@{ant-prefix}-card';

.@{card-prefix-cls} {
  box-shadow: 0 6px 8px rgba(180, 180, 180, 0.08);

  &-body {
    padding: 20px 24px;
  }
}

// Layout
@layout-content-prefix-cls: ~'@{ant-prefix}-layout-content';
@layout-header-prefix-cls: ~'@{ant-prefix}-layout-header';

.@{layout-content-prefix-cls} {
  padding-top: 60px;
  padding-bottom: 56px;
}

.@{layout-header-prefix-cls} {
  height: @layout-header-height * 2;
}

// Typography

h1.ant-typography {
  margin-bottom: 0;
  line-height: 49px;
  font-weight: 800;
}

@font-family: 'Montserrat', sans-serif;@border-color-base: #009EF2;@primary-color: #009EF2;@border-radius-base: 4px;@body-background: #E5E5E5;@btn-height-base: 44px;@btn-font-weight: 700;@btn-padding-horizontal-base: 25px;@btn-border-width: 2px;@btn-shadow: none;@btn-default-border: #009EF2;@btn-primary-color: #ffffff;@btn-primary-bg: #009EF2;@btn-primary-shadow: none;@btn-danger-color: #FFFFFF;@btn-danger-bg: #CE0019;@btn-text-shadow: none;@btn-border-radius-base: 4px;@btn-active-color: #009EF2;@btn-disable-color: #85d0f9;@btn-disable-bg: #e6f5fe;@btn-disable-border: #e6f5fe;@input-placeholder-color: #7C838B;@input-padding-vertical-base: 15px;@input-padding-horizontal-base: 18px;@input-line-height: 22px;@input-bg: #F2F3F5;@input-border-color: #F2F3F5;@input-active-border-color: #009EF2;@border-width-base: 2px;@input-placeholder-font-weight: 600;@input-font-weight: 700;@input-font-size: 10px;@input-height-base: 56px;@form-item-margin-bottom: 16px;@picker-bg: #F2F3F5;@select-border-color: #F2F3F5;@select-dropdown-bg: #F2F3F5;@select-dropdown-edge-child-vertical-padding: 16px;@control-padding-horizontal: 20px;@select-item-active-bg: none;@select-item-selected-bg: none;@select-item-selected-color: #009EF2;@select-dropdown-font-size: 13px;@select-dropdown-vertical-padding: 7px;@select-multiple-item-height: 40px;@select-selection-item-bg: #F2F3F5;@select-background: #F2F3F5;@checkbox-size: 24px;@checkbox-check-bg: #F2F3F5;@checkbox-border-width: 0;@checkbox-check-color: #ffffff;@card-background: #ffffff;@card-radius: 6px;@card-padding-base: 20px;@heading-3-size: 22px;@heading-1-size: 40px;@typography-title-font-weight: 700;@layout-header-height: 44px;@layout-header-padding: 22px 0;@layout-header-background: transparent;@layout-footer-background: #ffffff;@layout-footer-padding: 14px 0;