.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  border: 2px dashed #F2F3F5;
  border-radius: 6px;
  padding: 29px;
  outline: none;
}

.root.dragOver {
  border-color: #009EF2;
  background-color: rgba(0, 158, 242, 0.1);
}

.root > p {
  margin: 0;
}

.root.disabled {
  cursor: not-allowed;
}

.root.processing {
}

.caption {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
}

.captionLink {
  color: #009EF2;
  text-decoration: underline;
}

.root:hover:not(.dragOver):not(.disabled) .captionLink {
  text-decoration: none;
}

.subCaption {
  font-size: 12px;
  line-height: 14px;
  color: #9599A1;
}
