// Map less variables to CSS variables

@import '~antd/lib/style/themes/default.less';
@import './antd-theme';

// Define CSS vars to use it only in CSS files.
:root {
    --primary-color: @primary-color;
    --input-padding-horizontal-base: @input-padding-horizontal-base;
    --input-padding-vertical-base: @input-padding-vertical-base;
    --input-placeholder-font-weight: @input-placeholder-font-weight;
    --input-font-weight: @input-font-weight;
    --input-font-size: @input-font-size;
    --border-width-base: @border-width-base;
    --input-placeholder-color: @input-placeholder-color;
    --input-line-height: @input-line-height;
}

@font-family: 'Montserrat', sans-serif;@border-color-base: #009EF2;@primary-color: #009EF2;@border-radius-base: 4px;@body-background: #E5E5E5;@btn-height-base: 44px;@btn-font-weight: 700;@btn-padding-horizontal-base: 25px;@btn-border-width: 2px;@btn-shadow: none;@btn-default-border: #009EF2;@btn-primary-color: #ffffff;@btn-primary-bg: #009EF2;@btn-primary-shadow: none;@btn-danger-color: #FFFFFF;@btn-danger-bg: #CE0019;@btn-text-shadow: none;@btn-border-radius-base: 4px;@btn-active-color: #009EF2;@btn-disable-color: #85d0f9;@btn-disable-bg: #e6f5fe;@btn-disable-border: #e6f5fe;@input-placeholder-color: #7C838B;@input-padding-vertical-base: 15px;@input-padding-horizontal-base: 18px;@input-line-height: 22px;@input-bg: #F2F3F5;@input-border-color: #F2F3F5;@input-active-border-color: #009EF2;@border-width-base: 2px;@input-placeholder-font-weight: 600;@input-font-weight: 700;@input-font-size: 10px;@input-height-base: 56px;@form-item-margin-bottom: 16px;@picker-bg: #F2F3F5;@select-border-color: #F2F3F5;@select-dropdown-bg: #F2F3F5;@select-dropdown-edge-child-vertical-padding: 16px;@control-padding-horizontal: 20px;@select-item-active-bg: none;@select-item-selected-bg: none;@select-item-selected-color: #009EF2;@select-dropdown-font-size: 13px;@select-dropdown-vertical-padding: 7px;@select-multiple-item-height: 40px;@select-selection-item-bg: #F2F3F5;@select-background: #F2F3F5;@checkbox-size: 24px;@checkbox-check-bg: #F2F3F5;@checkbox-border-width: 0;@checkbox-check-color: #ffffff;@card-background: #ffffff;@card-radius: 6px;@card-padding-base: 20px;@heading-3-size: 22px;@heading-1-size: 40px;@typography-title-font-weight: 700;@layout-header-height: 44px;@layout-header-padding: 22px 0;@layout-header-background: transparent;@layout-footer-background: #ffffff;@layout-footer-padding: 14px 0;