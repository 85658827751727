.root {
  border-radius: 12px;
  padding: 24px 16px 32px 24px;
  background: #F2F3F5;
}

.header {
  padding-bottom: 22px;
}

.header > span {
  display: inline-block;
  color: var(--primary-color);
  font-size: 13px;
  line-height: 12px;
  padding-bottom: 12px;
  vertical-align: bottom;
  font-weight: 700;
  margin-left: 18px;
}

.header > .icon {
  width: 32px;
  height: 26px;
  fill: var(--primary-color);
}

.container {
  display: flex;
}

.container > .connectors {
  flex-grow: 0;
}

.container > .files {
  flex-grow: 1;
}

.files {
  padding-top: 12px;
  min-width: 0;
}

.files :global(.ant-progress-inner) {
  background: #ffffff;
}

.connectors {
  padding-right: 16px;
  padding-left: 10px;
}

.connectors > div {
  padding-top: 12px;
  border-left: 2px solid #ffffff;
}

.connectors > div > i {
  display: block;
  height: 0;
  border-bottom: 2px solid #ffffff;
  width: 20px;
  padding-top: 74px;
}

.connectors > div > i:first-child {
  padding-top: 34px;
}
