.steps {
  position: fixed;
  top: 60px;
  left: 0.5px;
  height: 100%;
  width: 250px;
  z-index: 2;
  transition: 500ms;
}

.stepsCard {
  position: absolute;
  left: 0;
  top: 195px;
}

@media (max-width: 1600px) {
  .steps {
    left: -210px;
  }
}

@media (max-width: 1600px) {
  .steps:hover {
    left: 0.5px;
    transition: 700ms; 
  }
}