.customFormItem {
    position: relative;
}

.customFormItem .label {
    position: absolute;
    z-index: 2;
    line-height: 1;
    color: var(--input-placeholder-color);
    font-weight: var(--input-font-weight);
    font-size: var(--input-font-size);
    cursor: text;
    pointer-events: none;
    top: 21px;
    left: calc(var(--input-padding-horizontal-base) + var(--border-width-base));
    transition-duration: 150ms;
    transition-timing-function: ease-out;
    transition-property: font-size, top;
}

.customFormItem.hasValue .label {
    top: 11px;
    font-weight: 500;
    font-size: 10px;
    cursor: default;
}

/* Input */
.customFormItem.hasValue :global(.ant-input-affix-wrapper) {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 12px;
}

.customFormItem.hasValue:global(.ant-form-item-has-feedback) :global(.ant-input-affix-wrapper) {
    padding-right: 32px;
}

.customFormItem.hasValue :global(:not(.ant-input-affix-wrapper)) > :global(.ant-input),
.customFormItem.hasValue :global(.ant-input-affix-wrapper) > :global(.ant-input) {
    padding-top: 25px;
    padding-bottom: 5px;
}

/* Picker */
.customFormItem :global(.ant-picker-input) > :global(input) {
    opacity: 0;
}

.customFormItem.hasValue :global(.ant-picker-input) > :global(input) {
    opacity: 1;
}

.customFormItem.hasValue :global(.ant-picker) {
    padding-top: 25px;
    padding-bottom: 5px;
}

/* Select */
.customFormItem.hasValue :global(.ant-select:not(.ant-select-multiple)) :global(.ant-select-selection-search) :global(.ant-select-selection-search-input),
.customFormItem.hasValue :global(.ant-select.ant-select-multiple) :global(.ant-select-selector),
.customFormItem.hasValue :global(.ant-select:not(.ant-select-multiple)) :global(.ant-select-selection-item) {
    padding-top: 25px;
    line-height: 22px;
    padding-bottom: 5px;
}

.customFormItem.hasValue :global(.ant-select.ant-select-multiple) :global(.ant-select-selector) {
    padding-top: 25px;
    line-height: 22px;
    padding-bottom: 5px;
}
