.root :global(.ant-modal-confirm-body) {
  text-align: center;
}

.root :global(.ant-modal-body) {
  padding: 16px;
}

.root :global(.ant-modal-content)  {
  box-shadow: 0 24px 56px rgba(180, 180, 180, 0.12);
  border-radius: 6px;
}

.root :global(.ant-modal-confirm-title) {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 6px;
}

.root :global(.ant-modal-confirm-content) {
  font-weight: 400;
  font-size: 12px;
  color: #9599A1;
  line-height: 15px;
  margin-top: 0;
}

.root :global(.ant-modal-confirm-btns) {
  float: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
